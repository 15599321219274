import React from 'react'

import SEO from './seo'
import Layout from './layout'
import BlockContentSanity from './BlockContentSanity/BlockContentSanity'
import MultiBackground from './multiBackground'
import MoviePosterImage from './moviePosterImage'

import styles from './documentEducationItem.module.scss'

/* 
    Component for presenting document item and education item 

    Styled with grid similarly to individual movie view (movie-template)
*/

const DocumentEducationItem = ({ document: { title, body, iconImage } }) => {
  const iconImageFluid = iconImage?.asset?.fluid

  return (
    <Layout>
      <SEO title={title} />
      <main className={styles.documentWrapper}>
        {/* DOCUMENT TITLE bar */}
        <div className={styles.titleWrapper}>
          <h1 className={`h2 ${styles.title}`}>{title}</h1>
        </div>

        {/* DOCUMENT DETAILS */}
        <div className={styles.documentDetails}>
          <BlockContentSanity blocks={body} />
        </div>

        {/* DOCUMENT SIDEBAR */}
        {/* if no specific icon image for document - don't show the image on sm screen and smaller */}
        <div
          className={`${!iconImageFluid ? 'xs-hide' : ''} ${styles.sideBar}`}
        >
          <MoviePosterImage
            img={iconImageFluid} // if no image given - passes undefined - handled by the component
          />
          {/* <div className={styles.sideBarContent}>
              we can put additional content here
            </div> */}
        </div>

        {/* DECORATION BAR - under DOCUMENT TITLE */}
        <div className={styles.decorationBar}>
          <MultiBackground
            img={null} // if no image given - passes undefined - handled by the component
            className={styles.decorationBar__bgImage}
            onTopGradients={[
              `linear-gradient(to left, var(--documentEducationDecorationBarBG2) var(--documentEducationDecorationBarColorPercent), transparent 100%)`,
              `linear-gradient(to right, transparent var(--documentEducationDecorationBarColorPercent), var(--documentEducationDecorationBarBG1) 100%)`,
            ]}
          />
        </div>
      </main>
    </Layout>
  )
}

export default DocumentEducationItem
